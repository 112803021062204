import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

//router配置
import router from './router'
import store from '@/store'

//在main.js中 引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {
  ak: ''
})

//设置body默认样式
document.body.style.margin = '0';
document.body.style.padding = '0';

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
