import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter)
import {
    getCookie
} from '@/utils/cookie'
// 导入store 
import store from '@/store'

//解决vue-router在3.0版本以上重复点菜单报错的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


//公共权限
const routes = [
    {
        path: '/',
        redirect: '/layout'
    },
    {
        path: '/layout',
        name: 'Layout',
        component: () => import('@/views/layout/Layout.vue'),
        children: [
            {
                path: '',
                component: () => import('@/views/home/Home.vue'),
                name: 'Home',
                meta: {
                    title: '首页',
                    icon: 'el-icon-attract'
                }
            }
        ]
    }
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router