import Vue from 'vue';
import Vuex from 'vuex'
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        routes: [], //路由存routes这个数组
        role: localStorage.getItem('role')//保存角色信息
    },
    mutations: {
        setRoutes(state, routes) {
            state.routes = routes
        },
        clearRoute(state) {
            state.routes = []
        },
        setRole(state, role) {
            state.role = role
        }
    },
    actions: {
        setRoleAction(context, role) {
            context.commit('setRoutes', role)
        }
    },
    getters: {
        getRoutes(state) {
            return state.routes
        }
    },
})

export default store