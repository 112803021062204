<template>
  <div id="app">
    <!-- 一级路由的坑 -->
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="less" scoped>
body{
	margin: 0;
}
</style>
